@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import '../../../sass/helpers/breakpoints';

.formButtonGroup {
	a > button {
		margin: 0;
	}
	button {
		margin-left: 15px;
	}
}


$transparent: transparent;
$white: #fff;
$black: #000;
$bg-white-1: #f6f7f8;
$green-light: #28A745;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #EE2C3C;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$bg-gray-1: #F6F8FA;
$font-gray-1: #959da5;
$font-gray-2: #BEBEBE;
$font-gray-3: #8C8C8C;
$font-gray-4: #90A0B7;
$font-gray-5: #979797;
$border-gray-1: #E1E4E8;
$border-gray-2: #ccc;
$border-gray-3: #E5E5E5;
$border-gray-4: #E0E0E0;
$border-gray-5: #D8D8D8;
$bg-tag-blue-1: #E8F5FA;
$font-tag-blue-1: #1264A3;
$border-tag-blue-1: #1D9BD1;
$font-tag-gray-1: #70758F;
$font-red-1: #EC454D;
$bg-feedback-blue-1: #E6F7FF;
$border-blue-1: #91D5FF;
$bg-gray-2: #F8F8F8;
$font-blue-1: #616061;
$font-blue-2: #2188FF;
$scq-bg-red: #FF5F76;
$mcq-bg-blue: #1680FF;
$coding-bg-purple: #7848DD;
$font-red-2: #D73A49;
$border-gray-6: #D1D5DA;
$checkbox-bg-checked: #0366D6;
$border-gray-7: #ddd;
$bg-gray-3: #F2F4F6;
$danger-red-1: #D72B40;
$font-gray-6: #A7A7A7;
$font-black-1: #5C5B5B;
$bg-gray-4: #eee;
$bg-green-1: #89E051;
$bg-draft-gold: #F7B645;
$bg-publish-green: #27AE60;
$suggestion-background: #fffee2;
$gray-1: #f1f1f1;
$gray-2: #5a5a5a;
$blue-refresh: #4a90e2;
$shadow-gray: rgba(141, 141, 141, 0.5);
$gray-border: #f1f1f1;
$yellow: #ffba49;
$font-red-3: #ff7270;
$green-font-1: #22af73;
$gray-font-1: #808080;
$blue-question-number: #4a90e2;
.button {
	position: relative;
	font-size: 14px;
	font-weight: $font-semi-bold;
	text-align: center;
	padding: 8px 16px;
	cursor: pointer;
	user-select: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	min-width: 96px;
	border-radius: 4px;
	background-color: #2fcb53;
	background-image: linear-gradient(rgb(35, 194, 119), rgb(57, 157, 108));
	box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset,
		rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;

	color: $white;
	transition: background 0.2s ease;
	&:hover {
		background-image: linear-gradient(rgb(31, 172, 106), rgb(50, 138, 95));
	}
}
.large {
	height: 40px;
	padding: 8px 48px;
}
.outline {
	background-color: $white;
	background-image: none;
	border-color: #2188ff;
	color: #2188ff;
	border: 2px solid;
	&:hover {
		background-image: linear-gradient(
			0deg,
			rgba(24, 130, 250, 1) 0%,
			rgba(53, 140, 255, 1) 100%
		);
		border-color: transparent;
		color: $white;
	}
}
.link {
	background-color: transparent;
	color: #4c4c4c;
	box-shadow: none;
	&:hover {
		color: #40a9ff;
		background-color: transparent;
	}
}
.secondary {
	background-image: linear-gradient(to bottom, #fff, #f3f5f7);
	color: #4c4c4c;
	&:hover {
		background-image: linear-gradient(to bottom, #fff, #f3f5f7);
	}
}

.blue {
	color: white !important;
	background: rgb(4, 101, 161);
	background-image: linear-gradient(
		0deg,
		rgba(24, 119, 242, 1) 0%,
		rgba(53, 140, 255, 1) 100%
	);

	color: #4c4c4c;
	&:hover {
		background-image: linear-gradient(
			0deg,
			rgba(24, 130, 250, 1) 0%,
			rgba(53, 140, 255, 1) 100%
		);
	}
}

.warning {
	background-image: linear-gradient(
		0deg,
		rgba(237, 172, 124, 1) 0%,
		rgba(250, 131, 41, 1) 100%
	);
	&:hover {
		background-image: linear-gradient(
			0deg,
			rgba(238, 161, 104, 1) 0%,
			rgba(244, 114, 16, 1) 100%
		);
	}
}

.warning-outline {
	background: #fff;
	color: #ffba49;
	border: 2px solid;
	border-color: #ffba49;
	box-shadow: none;
	&:hover {
		background-image: linear-gradient(
			180deg,
			rgba(237, 172, 124, 1) 0%,
			rgba(250, 131, 41, 1) 100%
		);
		box-shadow: rgba(237, 172, 124, 1) 0px 0px 0px 1px inset,
			rgba(250, 131, 41, 1) 0px -1px 1px 0px inset;
		color: white;
	}
}

.primary {
	background-color: #2fcb53;
	border-color: #2fcb53;
	color: $white;
	&:hover {
		background-color: #48dd84;
		border-color: #48dd84;
	}
}
.danger {
	color: $white;
	background-image: linear-gradient(rgb(236, 76, 71), rgb(214, 69, 64));
	box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset,
		rgba(67, 90, 111, 0.06) 0px -1px 1px 0px inset;
	&:hover {
		background-image: linear-gradient(rgb(234, 53, 48), rgb(208, 50, 45));
	}
}
.disabled {
	background: #bababa;
	border-color: #bababa;
	color: $white;
	pointer-events: none;
	box-shadow: none;
	opacity: 0.4;
	cursor: not-allowed;
	&:hover {
		background: #bababa;
	}
}
.loading {
	position: relative;
	margin-left: 5px;
	height: 24px;
	pointer-events: none;
	svg {
		circle {
			stroke: #fff;
		}
	}
}
.ripple {
	position: relative;
	overflow: hidden;
	transform: translate3d(0, 0, 0);
}

.ripple:after {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	pointer-events: none;
	background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
	background-repeat: no-repeat;
	background-position: 50%;
	transform: scale(10, 10);
	opacity: 0;
	transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
	transform: scale(0, 0);
	opacity: 0.3;
	transition: 0s;
}
