// html5doctor.com Reset Stylesheet v1.6.1
// Last Updated: 2010-09-17
// Author: Richard Clark - http://richclarkdesign.com

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
caption,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	background: transparent;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	font-size: 100%;
	background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

nav ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

a {
	margin: 0;
	padding: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
	text-decoration: none;
}

/* change colours to suit your needs */
ins {
	background-color: #ff9;
	color: #000;
	text-decoration: none;
}

/* change colours to suit your needs */
mark {
	background-color: #ff9;
	color: #000;
	font-style: italic;
	font-weight: bold;
}

del {
	text-decoration: line-through;
}

abbr[title],
dfn[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid $border-gray-2;
	margin: 1em 0;
	padding: 0;
}

input,
select {
	vertical-align: middle;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// prevent reset on text editor and its display

:global {
	.cke_editable,
	.editor_content {
		* {
			word-break: break-word;
		}
		color: $black;
		li {
			list-style-type: inherit;
			display: list-item;
			text-align: -webkit-match-parent;
		}

		ul,
		ol {
			display: block;
			list-style-type: disc;
			margin-block-start: 1em !important;
			margin-block-end: 1em !important;
			margin-inline-start: 0px !important;
			margin-inline-end: 0px !important;
			padding-inline-start: 40px !important;
		}

		ol {
			list-style-type: decimal;
		}

		p {
			display: block;
			-webkit-margin-before: 1__qem;
			-webkit-margin-after: 1__qem;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
		}

		div {
			display: block;
		}

		layer {
			display: block;
		}

		article,
		aside,
		footer,
		header,
		hgroup,
		main,
		nav,
		section {
			display: block;
		}
		blockquote {
			display: block;
			-webkit-margin-before: 1__qem;
			-webkit-margin-after: 1em;
			-webkit-margin-start: 40px;
			-webkit-margin-end: 40px;
		}

		figcaption {
			display: block;
		}

		figure {
			display: block;
			-webkit-margin-before: 1em;
			-webkit-margin-after: 1em;
			-webkit-margin-start: 40px;
			-webkit-margin-end: 40px;
		}

		q {
			display: inline;
		}

		q:before {
			content: open-quote;
		}

		q:after {
			content: close-quote;
		}

		center {
			display: block;
			/* special centering to be able to emulate the html4/netscape behaviour */
			text-align: -webkit-center;
		}

		hr {
			display: block;
			-webkit-margin-before: 0.5em;
			-webkit-margin-after: 0.5em;
			-webkit-margin-start: auto;
			-webkit-margin-end: auto;
			border-style: inset;
			border-width: 1px;
		}

		map {
			display: inline;
		}

		video {
			object-fit: contain;
		}
		h1 {
			display: block;
			font-size: 2em;
			-webkit-margin-before: 0.67__qem;
			-webkit-margin-after: 0.67em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			font-weight: bold;
		}

		:-webkit-any(article, aside, nav, section) h1 {
			font-size: 1.5em;
			-webkit-margin-before: 0.83__qem;
			-webkit-margin-after: 0.83em;
		}

		:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			h1 {
			font-size: 1.17em;
			-webkit-margin-before: 1__qem;
			-webkit-margin-after: 1em;
		}

		:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			h1 {
			font-size: 1em;
			-webkit-margin-before: 1.33__qem;
			-webkit-margin-after: 1.33em;
		}

		:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			h1 {
			font-size: 0.83em;
			-webkit-margin-before: 1.67__qem;
			-webkit-margin-after: 1.67em;
		}

		:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			:-webkit-any(article, aside, nav, section)
			h1 {
			font-size: 0.67em;
			-webkit-margin-before: 2.33__qem;
			-webkit-margin-after: 2.33em;
		}

		h2 {
			display: block;
			font-size: 1.5em;
			-webkit-margin-before: 0.83__qem;
			-webkit-margin-after: 0.83em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			font-weight: bold;
		}

		h3 {
			display: block;
			font-size: 1.17em;
			-webkit-margin-before: 1__qem;
			-webkit-margin-after: 1em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			font-weight: bold;
		}

		h4 {
			display: block;
			-webkit-margin-before: 1.33__qem;
			-webkit-margin-after: 1.33em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			font-weight: bold;
		}

		h5 {
			display: block;
			font-size: 0.83em;
			-webkit-margin-before: 1.67__qem;
			-webkit-margin-after: 1.67em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			font-weight: bold;
		}

		h6 {
			display: block;
			font-size: 0.67em;
			-webkit-margin-before: 2.33__qem;
			-webkit-margin-after: 2.33em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			font-weight: bold;
		}

		/* tables */

		table {
			display: table;
			border-collapse: separate;
			border-spacing: 2px;
			border-color: gray;
		}

		thead {
			display: table-header-group;
			vertical-align: middle;
			border-color: inherit;
		}

		tbody {
			display: table-row-group;
			vertical-align: middle;
			border-color: inherit;
		}

		tfoot {
			display: table-footer-group;
			vertical-align: middle;
			border-color: inherit;
		}

		/* for tables without table section elements (can happen with XHTML or dynamically created tables) */
		table > tr {
			vertical-align: middle;
		}

		col {
			display: table-column;
		}

		colgroup {
			display: table-column-group;
		}

		tr {
			display: table-row;
			vertical-align: inherit;
			border-color: inherit;
		}

		td,
		th {
			display: table-cell;
			vertical-align: inherit;
		}

		th {
			font-weight: bold;
		}

		caption {
			display: table-caption;
			text-align: -webkit-center;
		}

		/* lists */

		ul,
		menu,
		dir {
			display: block;
			list-style-type: disc;
			-webkit-margin-before: 1__qem;
			-webkit-margin-after: 1em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			-webkit-padding-start: 40px;
		}

		ol {
			display: block;
			list-style-type: decimal;
			-webkit-margin-before: 1__qem;
			-webkit-margin-after: 1em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
			-webkit-padding-start: 40px;
		}

		li {
			display: list-item;
			text-align: -webkit-match-parent;
		}

		ul ul,
		ol ul {
			list-style-type: circle;
		}

		ol ol ul,
		ol ul ul,
		ul ol ul,
		ul ul ul {
			list-style-type: square;
		}

		dd {
			display: block;
			-webkit-margin-start: 40px;
		}

		dl {
			display: block;
			-webkit-margin-before: 1__qem;
			-webkit-margin-after: 1em;
			-webkit-margin-start: 0;
			-webkit-margin-end: 0;
		}

		dt {
			display: block;
		}

		ol ul,
		ul ol,
		ul ul,
		ol ol {
			-webkit-margin-before: 0;
			-webkit-margin-after: 0;
		}
	}
}
