@import 'fonts';
@import '../variables/index';

html,
body {
	height: 100%;
	background-color: #f4f7f8;
	overflow-x: hidden;
}

body {
	font-size: 14px;
	line-height: 1.3;
	font-weight: 400;
	letter-spacing: normal;
	font-family: $font-family, -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol';
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: geometricPrecision;
	font-variant-ligatures: common-ligatures;
}

body * {
	transition: 0.2s ease-in-out;
}

h1,
h2,
h3,
h4 {
	font-weight: 400;
}

h1 {
	font-size: 32px;
}

h2 {
	font-size: 22px;
}

h3 {
	font-size: 20px;
}

h4 {
	font-size: 18px;
}

li {
	list-style-type: none;
}

small {
	font-size: 12px;
}

img {
	/* Makes sure image is never bigger than its parent */
	max-width: 100%;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #bcbcbc;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: #bcbcbc;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: #bcbcbc;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: #bcbcbc;
}

button:disabled {
	opacity: 0.6;
}
