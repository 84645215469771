// Trumps
// External styles, immutable styles, positively used for last-level overrides.

:global {
	@import 'react-table';

	.disabled,
	.disabled * {
		cursor: not-allowed;
	}
}
