@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import '../../../sass/helpers/breakpoints';

.formButtonGroup {
	a > button {
		margin: 0;
	}
	button {
		margin-left: 15px;
	}
}


$transparent: transparent;
$white: #fff;
$black: #000;
$bg-white-1: #f6f7f8;
$green-light: #28A745;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #EE2C3C;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$bg-gray-1: #F6F8FA;
$font-gray-1: #959da5;
$font-gray-2: #BEBEBE;
$font-gray-3: #8C8C8C;
$font-gray-4: #90A0B7;
$font-gray-5: #979797;
$border-gray-1: #E1E4E8;
$border-gray-2: #ccc;
$border-gray-3: #E5E5E5;
$border-gray-4: #E0E0E0;
$border-gray-5: #D8D8D8;
$bg-tag-blue-1: #E8F5FA;
$font-tag-blue-1: #1264A3;
$border-tag-blue-1: #1D9BD1;
$font-tag-gray-1: #70758F;
$font-red-1: #EC454D;
$bg-feedback-blue-1: #E6F7FF;
$border-blue-1: #91D5FF;
$bg-gray-2: #F8F8F8;
$font-blue-1: #616061;
$font-blue-2: #2188FF;
$scq-bg-red: #FF5F76;
$mcq-bg-blue: #1680FF;
$coding-bg-purple: #7848DD;
$font-red-2: #D73A49;
$border-gray-6: #D1D5DA;
$checkbox-bg-checked: #0366D6;
$border-gray-7: #ddd;
$bg-gray-3: #F2F4F6;
$danger-red-1: #D72B40;
$font-gray-6: #A7A7A7;
$font-black-1: #5C5B5B;
$bg-gray-4: #eee;
$bg-green-1: #89E051;
$bg-draft-gold: #F7B645;
$bg-publish-green: #27AE60;
$suggestion-background: #fffee2;
$gray-1: #f1f1f1;
$gray-2: #5a5a5a;
$blue-refresh: #4a90e2;
$shadow-gray: rgba(141, 141, 141, 0.5);
$gray-border: #f1f1f1;
$yellow: #ffba49;
$font-red-3: #ff7270;
$green-font-1: #22af73;
$gray-font-1: #808080;
$blue-question-number: #4a90e2;
.container {
	position: relative;
}
.label {
	display: block;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: 4px;
	letter-spacing: -0.05px;
	color: #4c4c56;
}
.input,
.textarea {
	padding: 0 10px;
	height: 40px;
	color: #4c4c56;
	resize: none;
	width: 100%;
	font-size: 14px;
	margin-bottom: 20px;
	border-radius: 3px;
	box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 0px 1px inset,
		rgba(67, 90, 111, 0.14) 0px 1px 2px inset;
	transition: 200ms $easing-expressive all;
	&:focus {
		box-shadow: rgba(67, 90, 111, 0.14) 0px 0px 2px inset,
			rgb(87, 154, 217) 0px 0px 0px 1px inset,
			rgba(16, 112, 202, 0.14) 0px 0px 0px 3px;
		outline: none;
	}
}

.hasError {
	border-color: #eb5055;
	box-shadow: rgba(235, 90, 80, 0.14) 0px 0px 2px inset,
			rgb(235, 80, 85) 0px 0px 0px 1px inset,
			rgba(235, 90, 80, 0.14) 0px 0px 0px 3px;
	&:focus {
		border-color: #eb5055;
	}
}

.readonly {
	box-shadow: rgba(67, 90, 111, 0.14) 0px 0px 0px 1px inset;
	background-color: rgb(245, 246, 247);
	&:focus {
		box-shadow: none;
		cursor: no-drop;
	}
}

.textarea {
	line-height: 1.5em;
	height: 100px;
	padding: 10px;
}

.helperText {
	font-weight: 600;
	font-size: 10px;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: block;
	position: relative;
	bottom: 5px;
	color: #adb1b3;
	font-family: sans-serif;
	top: -12px;
	line-height: 12px;
}

.error {
	color: #eb5055;
}

.enableResize {
	resize: both;
}
