@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import '../../../sass/helpers/breakpoints';

.formButtonGroup {
	a > button {
		margin: 0;
	}
	button {
		margin-left: 15px;
	}
}


$transparent: transparent;
$white: #fff;
$black: #000;
$bg-white-1: #f6f7f8;
$green-light: #28A745;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #EE2C3C;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$bg-gray-1: #F6F8FA;
$font-gray-1: #959da5;
$font-gray-2: #BEBEBE;
$font-gray-3: #8C8C8C;
$font-gray-4: #90A0B7;
$font-gray-5: #979797;
$border-gray-1: #E1E4E8;
$border-gray-2: #ccc;
$border-gray-3: #E5E5E5;
$border-gray-4: #E0E0E0;
$border-gray-5: #D8D8D8;
$bg-tag-blue-1: #E8F5FA;
$font-tag-blue-1: #1264A3;
$border-tag-blue-1: #1D9BD1;
$font-tag-gray-1: #70758F;
$font-red-1: #EC454D;
$bg-feedback-blue-1: #E6F7FF;
$border-blue-1: #91D5FF;
$bg-gray-2: #F8F8F8;
$font-blue-1: #616061;
$font-blue-2: #2188FF;
$scq-bg-red: #FF5F76;
$mcq-bg-blue: #1680FF;
$coding-bg-purple: #7848DD;
$font-red-2: #D73A49;
$border-gray-6: #D1D5DA;
$checkbox-bg-checked: #0366D6;
$border-gray-7: #ddd;
$bg-gray-3: #F2F4F6;
$danger-red-1: #D72B40;
$font-gray-6: #A7A7A7;
$font-black-1: #5C5B5B;
$bg-gray-4: #eee;
$bg-green-1: #89E051;
$bg-draft-gold: #F7B645;
$bg-publish-green: #27AE60;
$suggestion-background: #fffee2;
$gray-1: #f1f1f1;
$gray-2: #5a5a5a;
$blue-refresh: #4a90e2;
$shadow-gray: rgba(141, 141, 141, 0.5);
$gray-border: #f1f1f1;
$yellow: #ffba49;
$font-red-3: #ff7270;
$green-font-1: #22af73;
$gray-font-1: #808080;
$blue-question-number: #4a90e2;
.root {
	position: fixed;
	z-index: 100;
	right: 0px;
	bottom: 0px;
	top: 0px;
	left: 0px;
}

.backdrop {
	width: 100%;
	height: 100vh;
	position: fixed;
	background: #00000080;
	top: 0;
	right: 0;
	transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	outline: none;
	z-index: 20;
}

.main {
	display: flex;
	max-height: calc(100vh - 150px);
	max-width: calc(100vw - 150px);
	flex-direction: column;
	text-align: left;
	background: #fff;
	overflow-y: auto;
	position: relative;
	border-radius: 4px;
	z-index: 20;
}

.titleContainer {
	display: flex;
}

.content {
	flex: 1 1 auto;
	overflow: visible;
	overflow-y: scroll;
	padding: 72px 16px 16px;
}

.title {
	position: absolute;
	width: 100%;
	top: 0;
	background: white;
	font-size: 18px;
	font-weight: 500;
	letter-spacing: 0.18px;
	padding: 16px;
	border-bottom: 1px solid #e7e8f0;
	font-weight: bold;
}

.close {
	position: absolute;
	right: 12px;
	top: 12px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	z-index: 20;
	cursor: pointer;
	background-color: rgba(#d9d9d9, 0.4);
	transition: all 200ms $easing-expressive;
	&:hover {
		background-color: rgba(#d9d9d9, 0.7);
	}
}

.close:before,
.close:after {
	content: ' ';
	position: absolute;
	top: 8px;
	left: 15px;
	height: 16px;
	width: 2px;
	background-color: #b4b7bc;
}

.close:before {
	transform: rotate(45deg);
}

.close:after {
	transform: rotate(-45deg);
}

.hideButton {
	display: none;
}

.footer {
	position: sticky;
	bottom: 0;
	display: flex;
	justify-content: flex-end;
	height: 64px;
	background-color: #f0f0f0;
	padding: 16px;
}
