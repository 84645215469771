@import "../../../sass/variables/fonts";
@import "../../../sass/variables/spaces";
@import "../../../sass/variables/transitions";

@import '../../../sass/helpers/breakpoints';

.formButtonGroup {
	a > button {
		margin: 0;
	}
	button {
		margin-left: 15px;
	}
}


$transparent: transparent;
$white: #fff;
$black: #000;
$bg-white-1: #f6f7f8;
$green-light: #28A745;
$green-base: #68C721;
$blue-base: #6197DC;
$red-base: #EE2C3C;
$gray-lighter: #FAFBFB;
$gray-light: #F0F0F0;
$gray-base: #A8ACB1;
$gray-dark: #858B95;
$gray-darker: #353535;
$gray-darkest: #262626;
$black-light: #2d2d2d;
$bg-gray-1: #F6F8FA;
$font-gray-1: #959da5;
$font-gray-2: #BEBEBE;
$font-gray-3: #8C8C8C;
$font-gray-4: #90A0B7;
$font-gray-5: #979797;
$border-gray-1: #E1E4E8;
$border-gray-2: #ccc;
$border-gray-3: #E5E5E5;
$border-gray-4: #E0E0E0;
$border-gray-5: #D8D8D8;
$bg-tag-blue-1: #E8F5FA;
$font-tag-blue-1: #1264A3;
$border-tag-blue-1: #1D9BD1;
$font-tag-gray-1: #70758F;
$font-red-1: #EC454D;
$bg-feedback-blue-1: #E6F7FF;
$border-blue-1: #91D5FF;
$bg-gray-2: #F8F8F8;
$font-blue-1: #616061;
$font-blue-2: #2188FF;
$scq-bg-red: #FF5F76;
$mcq-bg-blue: #1680FF;
$coding-bg-purple: #7848DD;
$font-red-2: #D73A49;
$border-gray-6: #D1D5DA;
$checkbox-bg-checked: #0366D6;
$border-gray-7: #ddd;
$bg-gray-3: #F2F4F6;
$danger-red-1: #D72B40;
$font-gray-6: #A7A7A7;
$font-black-1: #5C5B5B;
$bg-gray-4: #eee;
$bg-green-1: #89E051;
$bg-draft-gold: #F7B645;
$bg-publish-green: #27AE60;
$suggestion-background: #fffee2;
$gray-1: #f1f1f1;
$gray-2: #5a5a5a;
$blue-refresh: #4a90e2;
$shadow-gray: rgba(141, 141, 141, 0.5);
$gray-border: #f1f1f1;
$yellow: #ffba49;
$font-red-3: #ff7270;
$green-font-1: #22af73;
$gray-font-1: #808080;
$blue-question-number: #4a90e2;
$red-base: #d72b3f;
$white: $white;
$black: #000;

.profileMenuBox {
	background-clip: padding-box;
	background-color: $white;
	border: 1px solid rgba(27, 31, 35, 0.15);
	border-radius: 4px;
	box-shadow: 0 3px 12px rgba(27, 31, 35, 0.15);
	left: 0;
	list-style: none;
	margin-top: 2px;
	padding-bottom: 5px;
	width: 220px;
	position: absolute;
	top: 100%;
	right: 0;
	left: auto;
	z-index: 20;
}
.content {
	display: block;
	padding: 0;
	.chevronRight {
		position: absolute;
		top: 43%;
		right: 20px;
		path {
			opacity: 0.5;
			stroke: #b2b2b2;
		}
	}
}
.menu {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0;
	.info {
		padding: 16px 0;
		background-color: rgba(67, 90, 111, 0.04);
		color: #435a6f;
	}
	.separator {
		width: 100%;
		line-height: 24px;
		list-style-type: none;
		padding: 0;
		border-top: 1px solid #e1e4e8;
	}
	.itemWrapper {
		width: 100%;
		position: relative;
	}
	.menuItemUser {
		width: 100%;
		text-align: left;
		padding: 0 16px;
		font-size: 12px;
		line-height: 22px;
		color: rgb(102, 120, 138);
		font-weight: $font-regular;
	}
	.email {
		width: 100%;
		text-align: left;
		padding: 4px 10px 4px 16px;
		font-size: 14px;
		line-height: 22px;
		color: #24292e;
		font-weight: $font-regular;
	}
	.item {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 16px;
		font-size: 14px;
		line-height: 1.3;
		color: #435a6f;
		cursor: pointer;
		.icon {
			margin-right: 12px;
			path {
				fill: #b2b2b2;
			}
		}
		&:hover {
			background-color: rgba(67, 90, 111, 0.04);
			.icon path {
				fill: $white;
			}
		}

		&.danger {
			color: $red-base;
			.icon path {
				fill: $red-base;
			}
			&:hover {
				color: $white;
				background-color: $red-base;
				.icon path {
					fill: $white;
				}
			}
		}
	}
}
